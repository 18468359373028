import { Image, Layout, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Burger from "../../components/layouts/HomeLayout/Burger";
import { useScroll } from "../../utils/useScroll";
import MobilityBanner from "../../assets/img/mobilitybanner.webp";
import Logo from "../../assets/img/logo-mobility.webp";
import Footer from "../../components/layouts/HomeLayout/Footer";
import GoogleMaps from "simple-react-google-maps";
import "./index.less";
import GoToTop from "../../components/GoToTop";
import { useDispatch, useSelector } from "react-redux";
import { getGamaLeasingBranchesContent } from "../../store/strapi/gamaleasing/thunks";
import {
  getBranchHeaderContent,
  getBranchesContent,
} from "../../store/strapi/gamaleasing";

function NewlineText(props) {
  const text = props?.text;
  return text ? text.split("\n").map((str) => <p key={str}>{str}</p>) : null;
}

function Branches() {
  const dispatch = useDispatch();
  const { scrollDirection } = useScroll();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const biggerThan1300 = useMediaPredicate("(min-width: 1330px)");

  const [isSelected, setIsSelected] = useState(false);
  const [lat, setLat] = useState(-20.29029581647586);
  const [lng, setLng] = useState(-70.12517206441758);
  const [key, setKey] = useState("");
  const [branch, setBranch] = useState("IQUIQUE");

  const [isHome, setIsHome] = useState();

  useEffect(() => {
    dispatch(getGamaLeasingBranchesContent());
  }, []);

  const branchHeader = useSelector(getBranchHeaderContent);
  const branchSections = useSelector(getBranchesContent);

  console.log(branchSections, "branchSectionsbranchSections");

  useEffect(() => {
    if (window.location.pathname === "/sucursales") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const handleChangeMap = (branch) => {
    setLat(parseFloat(branch.lat));
    setLng(parseFloat(branch.lng));
    setKey(branch.branch);
    setBranch(branch.branch);
  };

  const [visible, setVisible] = useState(false);
  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const burguerData = [
    {
      title: "Sucursales",
      link: "/sucursales",
    },
    {
      title: "Nuestros servicios",
      link: "#servicios",
    },
    {
      title: "Quienes Somos",
      link: "#nosotros",
    },
    { title: "Beneficios", link: "#beneficios" },
    { title: "Publicaciones", link: "#publicaciones" },
  ];

  const branches = [];

  const asociatedBranches = [];
  return (
    <div>
      <GoToTop />
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `70vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                branchHeader?.length === 0 || branchHeader === null
                  ? MobilityBanner
                  : branchHeader?.bannerImage?.data?.[0]?.attributes?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/mobility"}>
                      <Image preview={false} src={Logo} width={120} />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contacto">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/sucursales">
                                Sucursales
                              </a>
                              <a smooth href="/mobility#servicios">
                                Nuestros Servicios
                              </a>
                              <a smooth href="/mobility#nosotros">
                                Quienes Somos
                              </a>
                              <a smooth href="/mobility#beneficios">
                                Beneficios
                              </a>
                              <a smooth href="/mobility#publicaciones">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/sucursales">Sucursales</Link>
                              <Link to="/mobility#servicios">
                                Nuestros Servicios
                              </Link>
                              <Link to="/mobility#nosotros">Quienes Somos</Link>
                              <Link to="/mobility#beneficios">Beneficios</Link>
                              <Link to="/mobility#publicaciones">Publicaciones</Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
              }}
            >
              {branchHeader?.length === 0 || branchHeader === null
                ? "Sucursales"
                : branchHeader?.title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",
                paddingLeft: "0",
              }}
            >
              {branchHeader?.length === 0 || branchHeader === null
                ? "Encuentra toda la información que necesitas acerca de nuestros talleres a lo largo del país."
                : branchHeader?.title?.Detail}
            </h3>

            <Row>
              <Button
                onClick={() => setIsSelected(false)}
                id="servicios"
                style={{ width: "11rem", marginTop: "1.5rem" }}
              >
                {branchSections?.length === 0 || branchSections === null
                  ? "Talleres Gama"
                  : branchSections?.Title01}
              </Button>

              <Button
                onClick={() => setIsSelected(true)}
                id="servicios"
                style={{
                  width: "11rem",
                  marginTop: "1.5rem",
                  marginLeft: "1.5rem",
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "black",
                }}
              >
                {branchSections?.length === 0 || branchSections === null
                  ? "Talleres asociados"
                  : branchSections?.Title02}
              </Button>
            </Row>
          </div>
        </section>
      </Layout>
      <Row
        justify="start"
        style={{
          marginTop: "50px",
          marginLeft: biggerThan900 ? "150px" : "50px",
        }}
      >
        <p strong style={{ fontSize: "40px", fontWeight: "700" }}>
          {isSelected
            ? branchSections?.length === 0 || branchSections === null
              ? "Talleres Asociados"
              : branchSections?.Title02
            : branchSections?.length === 0
            ? "Talleres de Gama"
            : branchSections?.Title01}
        </p>
      </Row>
      <Row
        style={{
          width: "100%",
          paddingTop: "15px",
          paddingLeft: biggerThan1300 ? "100px" : "80px",
          marginBottom: "30px",
        }}
      >
        <Row style={{ width: biggerThan900 ? "60%" : "100%" }}>
          {branchSections?.length === 0 || branchSections === null
            ? !isSelected
              ? branches.map((branch) => (
                  <Row
                    key={branch.mail}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: biggerThan1300 ? "300px" : "200px",
                      lineHeight: "24px",
                    }}
                  >
                    <Col
                      onClick={() => handleChangeMap(branch)}
                      style={{
                        fontSize: "16px",
                        color: "#FF3200",
                        fontWeight: "700",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {branch.branch}
                    </Col>
                    <Col>{branch.name}</Col>
                    <Col>{branch.calle}</Col>
                    <Col>{branch.contacto}</Col>
                    <Col>{branch.mail}</Col>
                    <Col>{branch.telf}</Col>
                    <Col>{branch.serv}</Col>
                    <Row>
                      {branch.boton ? (
                        <a target="_blank" href={branch.url} rel="noreferrer">
                          <Button
                            style={{
                              width: "100px",
                              marginBottom: "70px",
                              marginTop: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            Ir al Mapa
                          </Button>
                        </a>
                      ) : null}
                    </Row>
                  </Row>
                ))
              : asociatedBranches.map((branch) => (
                  <Row
                    key={branch.nombre}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: biggerThan1300 ? "300px" : "200px",
                      lineHeight: "24px",
                    }}
                  >
                    <Col
                      onClick={() => handleChangeMap(branch)}
                      style={{
                        fontSize: "16px",
                        color: "#FF3200",
                        fontWeight: "700",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {branch.branch}
                    </Col>
                    <Col>{branch.name}</Col>
                    <Col>{branch.calle}</Col>
                    <Col>{branch.contacto}</Col>
                    <Col>{branch.mail}</Col>
                    <Col>{branch.telf}</Col>
                    <Col>{branch.serv}</Col>
                    <Row>
                      {branch.boton ? (
                        <a target="_blank" href={branch.url} rel="noreferrer">
                          <Button
                            style={{
                              width: "100px",
                              marginBottom: "70px",
                              marginTop: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            Ir al Mapa
                          </Button>
                        </a>
                      ) : null}
                    </Row>
                  </Row>
                ))
            : !isSelected
            ? branchSections?.GamaWorkshops?.map((branch) => (
                <Row
                  key={branch.Title}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: biggerThan1300 ? "300px" : "200px",
                    lineHeight: "24px",
                  }}
                >
                  <Col
                    onClick={() => handleChangeMap(branch)}
                    style={{
                      fontSize: "16px",
                      color: "#FF3200",
                      fontWeight: "700",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {branch.Title}
                  </Col>
                  <Col>
                    <NewlineText text={branch.Paragraph} />
                  </Col>
                  <Col>{branch.calle}</Col>
                  <Col>{branch.contacto}</Col>
                  <Col>{branch.mail}</Col>
                  <Col>{branch.telf}</Col>
                  <Col>{branch.serv}</Col>
                  <Row>
                    {branch?.Button?.Active === null ||
                    branch?.Button?.Active ? (
                      <a
                        target="_blank"
                        href={branch.Button.Hreference}
                        rel="noreferrer"
                      >
                        <Button
                          style={{
                            width: "100px",
                            marginBottom: "70px",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {branch.Button.Label
                            ? branch.Button.Label
                            : "Ir al Mapa"}
                        </Button>
                      </a>
                    ) : null}
                  </Row>
                </Row>
              ))
            : branchSections?.AssociatedWorkshops.map((branch) => (
                <Row
                  key={branch.Title}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: biggerThan1300 ? "300px" : "200px",
                    lineHeight: "24px",
                  }}
                >
                  <Col
                    onClick={() => handleChangeMap(branch)}
                    style={{
                      fontSize: "16px",
                      color: "#FF3200",
                      fontWeight: "700",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {branch.Title}
                  </Col>
                  <Col>
                    <NewlineText text={branch.Paragraph} />
                  </Col>
                  <Col>{branch.calle}</Col>
                  <Col>{branch.contacto}</Col>
                  <Col>{branch.mail}</Col>
                  <Col>{branch.telf}</Col>
                  <Col>{branch.serv}</Col>
                  <Row>
                    {branch?.Button?.Active === null ||
                    branch?.Button?.Active ? (
                      <a
                        target="_blank"
                        href={branch.Button.Hreference}
                        rel="noreferrer"
                      >
                        <Button
                          style={{
                            width: "100px",
                            marginBottom: "70px",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {branch.Button.Label
                            ? branch.Button.Label
                            : "Ir al Mapa"}
                        </Button>
                      </a>
                    ) : null}
                  </Row>
                </Row>
              ))}
        </Row>
        {biggerThan900 ? (
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FF3200",
              height: "345px",
              width: "330px",
              borderRadius: "20px",
              top: "12%",
              position: "sticky",
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                style={{ width: "25px", height: "25px", color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Col
                  style={{
                    color: "white",
                    fontSize: "21px",
                    paddingTop: "5px",
                  }}
                >
                  {branch}
                </Col>
              </Row>
              <div>
                <GoogleMaps
                  key={key}
                  apiKey={process.env.REACT_APP_API_KEY_MAPS}
                  style={{ width: "300px", height: "300px" }}
                  zoom={14}
                  center={{
                    lat: lat,
                    lng: lng,
                  }}
                  markers={{ lat: lat, lng: lng }}
                />
              </div>
            </Row>
          </Row>
        ) : null}
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default Branches;
