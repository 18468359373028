import React, { useEffect, useState } from "react";
import camionetas from "../../assets/img/berlingo y amarok 3.webp";
import { Col, Divider, Image, Layout, Modal, Row } from "antd";
import logo1 from "../../assets/img/image 71.webp";
import logo2 from "../../assets/img/image 72.webp";
import logo3 from "../../assets/img/12.webp";
import { CloseOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import Footer from "../../components/layouts/HomeLayout/Footer";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import "./index.less";
import UsadosBanner from "../../assets/img/Gamausados.webp";
import mayorista from "../../assets/img/mayorista.webp";
import { useScroll } from "../../utils/useScroll";
import Logo from "../../assets/img/logo-usados.webp";
import GoToTop from "../../components/GoToTop";
import { useDispatch, useSelector } from "react-redux";
import { getGamaUsadosHomeContent } from "../../store/strapi/gamausados/thunks";
import {
  getUsedHeaderContent,
  getUsedNavbarContent,
  getUsedSectionsContent,
  getUsedStatus,
} from "../../store/strapi/gamausados";
import { STATUS } from "../../utils/status";
import LoadingStrapi from "../LoadingStrapi";

function Used() {
  const dispatch = useDispatch();
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const root = document.querySelector("body");
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getGamaUsadosHomeContent());
  }, []);
  const usadosStatus = useSelector(getUsedStatus);
  const usedHeader = useSelector(getUsedHeaderContent);
  const usedNavbar = useSelector(getUsedNavbarContent);
  const usedSections = useSelector(getUsedSectionsContent);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    if (usadosStatus === STATUS.SUCCESS) {
      document.body.style.overflowY = "scroll";
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [usadosStatus]);

  useEffect(() => {
    if (visible === true) {
      return (root.style.overflow = "hidden");
    }

    return (root.style.overflow = "auto");
  }, [visible]);
  const onClose = () => {
    setVisible(!visible);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cleanModal = () => {
    document.getElementById("checkbox4").click();
    onClose();
  };

  const { scrollDirection } = useScroll();

  const burguerData = [
    {
      title: "Beneficios",
      link: "#beneficios",
    },
    // {
    //   title: "¿Eres Particular?",
    //   link: "#particular",
    // },
    {
      title: "¿Eres Mayorista?",
      link: "#mayorista",
    },
  ];

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  console.log("usedHeader", usedHeader);
  return (
    <div>
      <GoToTop />

      {loading && (
        <div style={{ zIndex: "9999", position: "relative", opacity: 1 }}>
          <LoadingStrapi />
        </div>
      )}

      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `100vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                usedHeader?.length === 0 || usedHeader === null
                  ? UsadosBanner
                  : usedHeader?.BannerImage[0]?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "https://gamamobility.cl/"
                          : usedNavbar?.TopLinks[0]?.Hreference
                      }
                      rel="noreferrer"
                    >
                      {usedNavbar?.length === 0 || usedNavbar === null
                        ? "GAMA MOBILITY"
                        : usedNavbar?.TopLinks[0]?.label}
                    </a>
                  </li>
                  <li>
                    <a
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      href={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "/"
                          : usedNavbar?.TopLinks[1]?.Hreference
                      }
                    >
                      {usedNavbar?.length === 0 || usedNavbar === null
                        ? "GAMA LEASING"
                        : usedNavbar?.TopLinks[1]?.label}
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "https://www.gamarent.cl/"
                          : usedNavbar?.TopLinks[2]?.Hreference
                      }
                    >
                      {usedNavbar?.length === 0 || usedNavbar === null
                        ? "RENT A CAR"
                        : usedNavbar?.TopLinks[2]?.label}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "https://www.tripp.cl/"
                          : usedNavbar?.TopLinks[3]?.Hreference
                      }
                    >
                      {usedNavbar?.length === 0 || usedNavbar === null
                        ? "TRIPP"
                        : usedNavbar?.TopLinks[3]?.label}
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "/usados"
                            ? "#FF3200"
                            : "white",
                      }}
                      to={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "/usados"
                          : usedNavbar?.TopLinks[4]?.Hreference
                      }
                    >
                      {usedNavbar?.length === 0 || usedNavbar === null
                        ? "GAMA USADOS"
                        : usedNavbar?.TopLinks[4]?.label}
                    </Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link
                      to={
                        usedNavbar?.length === 0 || usedNavbar === null
                          ? "/usados"
                          : usedNavbar?.TopLinks[4]?.Hreference
                      }
                    >
                      <Image
                        preview={false}
                        src={
                          usedNavbar?.length === 0 || usedNavbar === null
                            ? Logo
                            : usedNavbar?.Logo?.url
                        }
                        width={biggerThan900 ? 180 : 120}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link
                        to={
                          usedNavbar?.length === 0 || usedNavbar === null
                            ? "/contacto-usado"
                            : usedNavbar?.NavButton[0]?.Hreference
                        }
                      >
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          {usedNavbar?.length === 0 || usedNavbar === null
                            ? "Conversemos"
                            : usedNavbar?.NavButton[0].Label}
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          <a
                            smooth
                            href={
                              usedNavbar?.length === 0 || usedNavbar === null
                                ? "#servicios"
                                : usedNavbar?.NavLinks[0].Hreference
                            }
                          >
                            {usedNavbar?.length === 0 || usedNavbar === null
                              ? "Beneficios"
                              : usedNavbar?.NavLinks[0]?.label}
                          </a>
                          <a
                            smooth
                            href={
                              usedNavbar?.length === 0 || usedNavbar === null
                                ? "#mayorista"
                                : usedNavbar?.NavLinks[1].Hreference
                            }
                          >
                            {usedNavbar?.length === 0 || usedNavbar === null
                              ? "¿Eres mayorista?"
                              : usedNavbar?.NavLinks[1]?.label}
                          </a>
                        </div>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            id="checkbox4"
                            className="checkbox4 visuallyHidden"
                          ></input>
                          <label htmlFor="checkbox4" onClick={() => onClose()}>
                            <div className="hamburger hamburger4">
                              <span className="bar bar1"></span>
                              <span className="bar bar2"></span>
                              <span className="bar bar3"></span>
                              <span className="bar bar4"></span>
                              <span className="bar bar5"></span>
                            </div>
                          </label>

                          <div
                            className={
                              visible === true ? "modal modal-active" : "modal"
                            }
                          >
                            <div className="delimiter"></div>
                            <div className="container-modal">
                              <div
                                className="header-modal"
                                style={{ height: "55%" }}
                              >
                                <div className="nav-group">
                                  {burguerData?.map((item) => (
                                    <a
                                      key={item.title}
                                      smooth
                                      href={item.link}
                                      onClick={cleanModal}
                                    >
                                      {item.title}
                                    </a>
                                  ))}
                                </div>

                                <div className="button-group">
                                  <Link
                                    to={
                                      usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "/contacto-usado"
                                        : usedNavbar?.NavButton[0]?.Hreference
                                    }
                                  ></Link>
                                </div>
                              </div>
                              <div
                                className="footer-modal"
                                style={{
                                  height: "45%",
                                  backgroundColor: "#2D2D2D",
                                }}
                              >
                                <ul>
                                  <li>
                                    <a
                                      target="_blank"
                                      href={
                                        usedNavbar?.length === 0 ||
                                        usedNavbar === null
                                          ? "https://gamamobility.cl/"
                                          : usedNavbar?.TopLinks[0]?.Hreference
                                      }
                                      rel="noreferrer"
                                    >
                                      {usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "GAMA MOBILITY"
                                        : usedNavbar?.TopLinks[0]?.label}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      style={{
                                        color:
                                          window.location.pathname === ""
                                            ? "#FF3200"
                                            : "white",
                                      }}
                                      href={
                                        usedNavbar?.length === 0 ||
                                        usedNavbar === null
                                          ? "/"
                                          : usedNavbar?.TopLinks[1]?.Hreference
                                      }
                                    >
                                      {usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "GAMA LEASING"
                                        : usedNavbar?.TopLinks[1]?.label}
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href={
                                        usedNavbar?.length === 0 ||
                                        usedNavbar === null
                                          ? "https://www.gamarent.cl/"
                                          : usedNavbar?.TopLinks[2]?.Hreference
                                      }
                                    >
                                      {usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "RENT A CAR"
                                        : usedNavbar?.TopLinks[2]?.label}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={
                                        usedNavbar?.length === 0 ||
                                        usedNavbar === null
                                          ? "https://www.tripp.cl/"
                                          : usedNavbar?.TopLinks[3]?.Hreference
                                      }
                                    >
                                      {usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "TRIPP"
                                        : usedNavbar?.TopLinks[3]?.label}
                                    </a>
                                  </li>
                                  <li>
                                    <Link
                                      style={{
                                        color:
                                          window.location.pathname === "/usados"
                                            ? "#FF3200"
                                            : "white",
                                      }}
                                      to={
                                        usedNavbar?.length === 0 ||
                                        usedNavbar === null
                                          ? "/usados"
                                          : usedNavbar?.TopLinks[4]?.Hreference
                                      }
                                    >
                                      {usedNavbar?.length === 0 ||
                                      usedNavbar === null
                                        ? "GAMA USADOS"
                                        : usedNavbar?.TopLinks[4]?.label}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
              }}
            >
              {usedHeader?.length === 0 || usedHeader === null
                ? "Usados"
                : usedHeader?.Title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "35px",
                fontSize: "40px",
                fontWeight: "400",
                paddingLeft: "0",
              }}
            >
              {usedHeader?.length === 0 || usedHeader === null
                ? "La compra de tu auto respaldada por la experiencia de Gama."
                : usedHeader?.Title?.Detail}
            </h3>

            <Link
              to={
                usedHeader?.length === 0 || usedHeader === null
                  ? "/contacto-usado"
                  : usedHeader?.Button[0]?.Hreference
              }
            >
              <Button
                id="servicios"
                style={{ width: "11rem", marginTop: "1.5rem" }}
              >
                {usedHeader?.length === 0 || usedHeader === null
                  ? "Conversemos"
                  : usedHeader?.Button[0]?.Label}
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
      <Row style={{ display: "flex", marginTop: "100px" }}>
        <img
          style={{
            widht: biggerThan900 ? "753px" : "auto",
            height: biggerThan900 ? "317px" : "200px",
          }}
          src={
            usedSections?.length === 0 || usedSections === null
              ? camionetas
              : usedSections && usedSections[0]?.Image?.url
          }
        />
        <Col>
          {biggerThan900 ? (
            <Divider
              style={{
                backgroundColor: "#FF3200",
                height: "200px",
                width: "2px",
                marginLeft: "100px",
              }}
              type="vertical"
            />
          ) : null}
        </Col>
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            align: "middle",
            marginLeft: biggerThan900 ? "100px" : "40px",
          }}
        >
          <Col
            style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom: "30px",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Beneficios Gama Usados"
              : usedSections && usedSections[0]?.Title}
          </Col>
          <Col
            style={{
              width: "518px",
              lineHeight: "32px",
              color: "#434343",
              fontSize: "18px",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Súbete a un seminuevo, con el respaldo de Gama. Precios convenientes, con variedad de marcas y modelos. Haz tu compra de manera confiable, segura y rápida."
              : usedSections && usedSections[0]?.Paragraph}
          </Col>
        </Row>
      </Row>
      <Row
        id="mayorista"
        justify="space-evenly"
        align="middle"
        style={{
          marginTop: "60px",
          marginBottom: "60px",
          display: biggerThan900 ? "" : "flex",
          flexDirection: biggerThan900 ? "" : "column",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Col>
            <img
              src={
                usedSections?.length === 0 || usedSections === null
                  ? logo1
                  : usedSections && usedSections[0]?.Card[0]?.Icon?.url
              }
            />
          </Col>
          <Col
            style={{
              fontSize: "24px",
              fontWeight: "800",
              width: "203px",
              textAlign: "center",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Compra rápida, segura y confiable"
              : usedSections && usedSections[0]?.Card[0]?.Title}
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Col>
            <img
              src={
                usedSections?.length === 0 || usedSections === null
                  ? logo2
                  : usedSections[0]?.Card[1]?.Icon?.url
              }
            />
          </Col>
          <Col
            style={{
              fontSize: "24px",
              fontWeight: "800",
              width: "203px",
              textAlign: "center",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Transparencia y fiabilidad"
              : usedSections && usedSections[0]?.Card[1]?.Title}
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Col>
            <img
              src={
                usedSections?.length === 0 || usedSections === null
                  ? logo3
                  : usedSections[0]?.Card[2]?.Icon?.url
              }
            />
          </Col>
          <Col
            style={{
              fontSize: "24px",
              fontWeight: "800",
              width: "203px",
              textAlign: "center",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Seguridad y respaldo"
              : usedSections && usedSections[0]?.Card[2]?.Title}
          </Col>
        </Row>
      </Row>
      <div style={{ backgroundColor: "#2D2D2D" }}></div>
      <Row
        justify="space-around"
        style={{
          backgroundImage: `url(${
            biggerThan900
              ? usedSections?.length === 0 || usedSections === null
                ? mayorista
                : usedSections && usedSections[1]?.Image?.url
              : null
          })`,
          width: "100%",
          backgroundSize: "cover",
          height: "800px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "100px",
          }}
        >
          <Col
            style={{
              fontSize: "32px",
              fontWeight: "700",
              textAlign: "center",
              lineHeight: "24px",
              marginTop: "80px",
              marginBottom: "40px",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "¿Eres Cliente Mayorista?"
              : usedSections && usedSections[1]?.Title}
          </Col>

          <Col
            style={{
              width: "550px",
              textAlign: "center",
              lineHeight: "24px",
              marginBottom: "20px",
              color: "#434343",
            }}
          >
            {usedSections?.length === 0 || usedSections === null
              ? "Somos tu socio proveedor. Opera con nosotros a través de una plataforma exclusiva y segura de compra de vehículos, donde puedes ver diariamente nuestro stock, y ser parte de los procesos de compra seguros y transparentes a precios de mayoristas."
              : usedSections && usedSections[1]?.Paragraph}
          </Col>

          <div className="modal-container">
            <Row justify="space-evenly" style={{ marginTop: "30px" }}>
              <Modal
                title={null}
                footer={null}
                closable={false}
                bodyStyle={{
                  backgroundColor: "black",
                  paddingLeft: "100px",
                  border: "2px solid #FF3200",
                }}
                width="654px"
                height="609px"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div style={{ color: "white" }}>
                  <CloseOutlined
                    onClick={handleCancel}
                    style={{
                      marginLeft: "500px",
                      backgroundColor: "#FF5F00",
                      color: "white",
                      padding: "8px 8px",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                  />
                  <Col style={{ fontSize: "24px" }}>
                    ¿Quieres ser un mayorista Gama?
                  </Col>
                  <Col
                    style={{
                      lineHeight: "21.6px",
                      width: "350px",
                      marginBottom: "25px",
                    }}
                  >
                    Regístrate para operar con nosotros a través de la
                    plataforma www.attr.cl , envíanos los siguiente datos al
                    correo
                    <span style={{ color: "#FF5F00" }}>
                      {" "}
                      attr@gamaleasing.cl
                    </span>
                  </Col>
                  <Col style={{ fontSize: "20px" }}>
                    Indicar/ Adjuntar los siguientes datos :
                  </Col>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      • Antigüedad mínima de Giro de compraventa en SII de 6
                      meses
                    </li>

                    <li>• Giro de compraventa de vehículos en SII</li>

                    <li>• Nombre Completo del Agente en Automotora</li>

                    <li>• Teléfono del Agente en Automotora</li>

                    <li>• Dirección</li>

                    <li>• Teléfono</li>
                    <li>• Mail</li>
                    <li>• Página Web; Redes Sociales</li>
                    <li>• Foto del Rol Empresa (E-rut Sii)</li>
                    <li>• Certificado de Estatuto Vigente Empresa</li>
                    <li>
                      • Certificado de Giro de Compraventa de Vehículos en SII
                    </li>
                    <li>
                      • Foto CI ambos lados de él o los apoderados autorizados y
                      vigentes
                    </li>
                  </ul>
                </div>
              </Modal>
              <Col>
                <a
                  onClick={showModal}
                  style={{
                    color: "black",
                    textDecoration: "underline",
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                >
                  Revisa los requisitos aqui.
                </a>
              </Col>
              <Col>
                <a
                  href={
                    usedSections?.length === 0 || usedSections === null
                      ? "https://autotraderschile.com/"
                      : usedSections && usedSections[1]?.Button[0]?.Hreference
                  }
                >
                  <Button>
                    {usedSections?.length === 0 || usedSections === null
                      ? "Ingresar a la plataforma"
                      : usedSections && usedSections[1]?.Button[0]?.Label}
                  </Button>
                </a>
              </Col>
            </Row>

            <Col
              style={{
                marginLeft: biggerThan900 ? "200px" : "110px",
                paddingBottom: biggerThan900 ? "0px" : "16px",
                marginTop: "16px",
              }}
            >
              <Link
                to={
                  usedSections?.length === 0 || usedSections === null
                    ? "/contacto-usado"
                    : usedSections && usedSections[1]?.Button[1]?.Hreference
                }
              >
                <Button>
                  {usedSections.length === 0 || usedSections === null
                    ? "Conversemos"
                    : usedSections && usedSections[1]?.Button[1]?.Label}
                </Button>
              </Link>
            </Col>
          </div>
        </Row>
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default Used;
