import axios from "axios";

import { STRAPI_API_URL } from "../../url";

const BASE_URL = STRAPI_API_URL;

export const getGamaMainContent = async () => {
  const { data: navbar } = await axios.get(
    `${BASE_URL}/gmlsmains/ahy9spmgw9m56b7g7l24prxa?populate[0]=Navbar.Logo&populate[1]=Navbar.TopLinks&populate[2]=Navbar.NavLinks&populate[3]=Navbar.NavButton`
  );

  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/ahy9spmgw9m56b7g7l24prxa?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gmlsmains/ahy9spmgw9m56b7g7l24prxa?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Card.Button&populate[3]=Sections.Button`
  );

  const { data: footer } = await axios.get(
    `${BASE_URL}/gmlsmains/ahy9spmgw9m56b7g7l24prxa?populate[0]=Footer.Logo&populate[1]=Footer.SocialNetwork.Icon&populate[2]=Footer.FooterLinks.FooterLink&populate[3]=Footer.FooterNewsletter.NewsletterButton&populate[4]=Footer.ContactCard.Icon&populate[5]=Footer.BottomArea.BottomLinks`

  );

  return {
    navbar: navbar.data.Navbar,
    header: header.data.Header,
    sections: sections.data.Sections,
    footer: footer.data.Footer,
  };
};

export const getGamaFleetContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/qvh716a9p5bx6vicg1c8cffj?populate[0]=Header.BannerImage&populate[1]=Header.Title`
  );
  const { data: gallery } = await axios.get(
    `${BASE_URL}/gmlsmains/qvh716a9p5bx6vicg1c8cffj?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );
  return {
    header: header?.data,
    gallery: gallery?.data?.Sections[0].Card,
  };
};

export const getGamaContactContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gmlsmains/q41pru5qagq8yoh44ciqekyq?populate[0]=Header.BannerImage&populate[1]=Header.Title`
  );
  const { data: sider } = await axios.get(
    `${BASE_URL}/gmlsmains/q41pru5qagq8yoh44ciqekyq?populate[0]=Sections.Image&populate[1]=Sections.Card.Icon&populate[2]=Sections.Button`
  );

  return {
    header: header?.data,
    sections: sider?.data?.Sections,
  };
};

export const getGamaBranchesContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/wt7k94w2zsnlps26kiz72r11?populate[0]=Header.BannerImage&populate[1]=Header.Title`
  );
  const { data: branches } = await axios.get(
    `${BASE_URL}/gm-mains/wt7k94w2zsnlps26kiz72r11?populate[0]=Sections.Image&populate[1]=Sections.Card.Button`
  );
  return {
    header: header?.data,
    branches: branches?.data?.Sections,
  };
};
export const getSinisterContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/k72rzmng21vbzvkcm8exu3c9?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/k72rzmng21vbzvkcm8exu3c9?populate[0]=Sections.Image&populate[1]=Sections.Card`
  );

  return {
    header: header?.data,
    sections: sections?.data,
  };
};

export const getPaymentContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/o75pjxtq9z56xtq29p99lcra?populate[0]=Header.BannerImage&populate[1]=Header.Titlepopulate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/o75pjxtq9z56xtq29p99lcra?populate[0]=Sections.Image&populate[1]=Sections.Title&populate[2]=Sections.Button&populate[3]=Sections.Card.Icon`
  );

  return {
    header: header?.data,
    sections: sections?.data,
  };
};

export const getFQAContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/xyuag96q8t0r9s7q6vus0j1r?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );

  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/xyuag96q8t0r9s7q6vus0j1r?populate[0]=Sections.Card`
  );

  return {
    header: header?.data,
    sections: sections?.data,
  };
};

export const getPostsContent = async () => {
  const { data: header } = await axios.get(
    `${BASE_URL}/gm-mains/vjpjtgnw58e47c3auzw36i8q?populate[0]=Header.BannerImage&populate[1]=Header.Title&populate[2]=Header.Button`
  );
  const { data: sections } = await axios.get(
    `${BASE_URL}/gm-mains/vjpjtgnw58e47c3auzw36i8q?populate[0]=Sections.Card.Icon`
  );
  return {
    header: header?.data,
    sections: sections?.data,
  };
};
