import { Col, Image, Layout, Row, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Burger from "../../components/layouts/HomeLayout/Burger";
import Footer from "../../components/layouts/HomeLayout/Footer";
import ContactBanner from "../../assets/img/contact-banner.webp";
import { useScroll } from "../../utils/useScroll";
import { useMediaPredicate } from "react-media-hook";
import Logo from "../../assets/img/logo-mobility.webp";
import { useDispatch, useSelector } from "react-redux";
import { getGamaLeasingFAQContent } from "../../store/strapi/gamaleasing/thunks";
import {
  getFAQHeaderContent,
  getFAQSectionContent,
} from "../../store/strapi/gamaleasing";

function FAQ() {
  const biggerThan900 = useMediaPredicate("(min-width: 992px)");
  const [visible, setVisible] = useState(false);
  const [isHome, setIsHome] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamaLeasingFAQContent());
  }, []);
  const { scrollDirection } = useScroll();
  const faqHeader = useSelector(getFAQHeaderContent);
  const faqSections = useSelector(getFAQSectionContent);

  const { Panel } = Collapse;

  useEffect(() => {
    if (window.location.pathname === "/FAQ") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [window.location.pathname]);

  const burguerData = [
    {
      title: "Sucursales",
      link: "/sucursales",
    },
    {
      title: "Nuestros servicios",
      link: "#servicios",
    },
    {
      title: "Quienes Somos",
      link: "#nosotros",
    },
    { title: "Beneficios", link: "#beneficios" },
    { title: "Publicaciones", link: "#publicaciones" },
  ];

  const Usados = [
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué tipo de vehículos tienen disponibles a la venta?"
          : faqSections[2]?.Card[0]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Gama Usados ofrece desde Camionetas Pick Up, Furgones pequeños y grandes, Furgones de pasajeros y Camiones ¾ y grandes.También tenemos autos particulares, como Hatchback, Sedan, Suv y Camionetas."
          : faqSections[2]?.Card[0]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Dónde puedo ver los autos?"
          : faqSections[2]?.Card[1]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "En la sucursal de Gama, ubicada en Américo Vespucio Norte 1300, Pudahuel, Región Metropolitana."
          : faqSections[2]?.Card[1]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Cómo me puedo contactar para cotizar un vehículo?"
          : faqSections[2]?.Card[2]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Te puedes contactar con el área encargada al correo electronico: ventausados@gamaleasing.cl."
          : faqSections[2]?.Card[2]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Quién paga la transferencia del vehículo?"
          : faqSections[2]?.Card[3]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "El comprador asume el gasto por transferencia."
          : faqSections[2]?.Card[3]?.Paragraph,
    },
  ];
  const Renting = [
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué es Renting?"
          : faqSections[1]?.Card[0]?.Title,
      answer: (
        <div>
          {faqSections?.length === 0 || faqSections === null
            ? "Renting es un arriendo para personas por 12, 24 o 36 Meses de un auto 0 kilómetros, permitiéndote disfrutar de un auto sin las preocupaciones asociadas (patente, impuesto verde, seguro y mantenciones), ya que va todo incluido dentro de la cuota que cancelas mensualmente. Solo tienes que hacerte cargo del combustible que uses y los peajes. Esta modalidad la puedes contratar con tripp, visita su catálogo en este"
            : faqSections && faqSections[1]?.Card[0]?.Paragraph}
          <a target="_blank" href="https://tripp.cl/" rel="noreferrer">
            Click aqui.
          </a>
        </div>
      ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Cuáles son los requisitos para acceder al servicio?"
          : faqSections[1]?.Card[1]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null ? (
          <ul>
            <li>-Ser Mayor de 23 años.</li>
            <li>-Cédula de Identidad Vigente.</li>
            <li>-Licencia de Conducir.</li>
            <li>-Antecedentes Comerciales Positivos.</li>
            <li>
              -El monto del valor de tu renting mensual no supere el 30% de tu
              renta liquida.
            </li>
            <li>-Medio de pago: Tarjeta Debito / Tarjeta Crédito.</li>
          </ul>
        ) : (
          faqSections[1]?.Card[1]?.Paragraph
        ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué pasa al finalizar el contrato?"
          : faqSections[1]?.Card[2]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Sí, los servicios están suscritos sólo en las ciudades donde hay talleres Gama."
          : faqSections[1]?.Card[2]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿A quien le corresponde realizar las mantenciones?"
          : faqSections[1]?.Card[3]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null ? (
          <div>
            <p>
              Si tu suscripción fue por un plan Classic, podrás renovar por otro
              plan, con un descuento adicional por ser cliente tripp. Y en el
              caso de las suscripciones Plus, tendrás dos opciones:
            </p>
            <ul>
              <li>
                -Pagas el VCG (valor cesión garantizada) y ¡el auto es todo
                tuyo!
              </li>
              <li>-Renovar por otro plan. </li>
            </ul>
          </div>
        ) : (
          faqSections[1]?.Card[3]?.Paragraph
        ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿A quien le corresponde realizar las mantenciones?"
          : faqSections[1]?.Card[4]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null ? (
          <div>
            <p>
              Las mantenciones del auto las realiza Gama, con previo
              agendamiento a través de un Ejecutivo Post Venta, quien coordinará
              el retiro y entrega del automóvil en el domicilio que el cliente
              requiera.
            </p>
            <p>*Según aplique la cobertura (Región Metropolitana).</p>
          </div>
        ) : (
          faqSections[1]?.Card[4]?.Paragraph
        ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué pasa si le quiero hacer alguna modificación o instalar algún accesorio al vehículo?"
          : faqSections[1]?.Card[5]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Todo accesorio que se requiera instalar, debe ser autorizado por Gama e instalado en nuestros talleres."
          : faqSections[1]?.Card[5]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué tipos de planes se pueden contratar para renting?"
          : faqSections[1]?.Card[6]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null ? (
          <div>
            <p>
              Puedes suscribirte al plan Classic, que consiste en un contrato de
              arriendo desde los 12, 24 o 36 meses, que te permite siempre
              disfrutar de un auto nuevo al finalizar la suscripción, sin las
              preocupaciones de comprar un auto nuevo.
            </p>
            <p>
              <span>Plus:</span> Con esta suscripción te puedes quedar con el
              vehículo pagando una última cuota llamada VCG (valor cesión
              garantizada). Y el valor de esta cuota ya lo sabrás al momento de
              suscribirte.
            </p>
          </div>
        ) : (
          faqSections[1]?.Card[6]?.Paragraph
        ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué sucede si me excedo de los kilómetros contratados del plan?"
          : faqSections[1]?.Card[7]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Podrás devolver el vehículo con un máximo de 1.000 kilómetros excedidos del plan contratado. Pasado este límite se cobrará una multa de 10 UF más IVA cada 1.000 km adicionales."
          : faqSections[1]?.Card[7]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Al final del contrato tengo que pagar un “cuotón”?"
          : faqSections[1]?.Card[8]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Solo si te suscribes a la opción Plus, y esta cuota final o VCG está definida desde un principio."
          : faqSections[1]?.Card[8]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "Con mi crédito automotriz / compra inteligente cambio el auto muy seguido ¿Se puede con Renting?"
          : faqSections[1]?.Card[9]?.Title,
      answer: (
        <div>
          {faqSections?.length === 0 || faqSections === null
            ? "Así es, al finalizar tu contrato de arriendo, puedes tomar otro Renting 0 KM, disfrutando de los beneficios de la movilidad con tripp"
            : faqSections[1]?.Card[9]?.Paragraph}
          <a target="_blank" href="https://tripp.cl/" rel="noreferrer">
            {" "}
            Click aqui.
          </a>
        </div>
      ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué valor tiene la cuota inicial?"
          : faqSections[1]?.Card[10]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Al contrario de los créditos automotrices, contratando con Renting no tienes que hacer una inversión inicial en la mayoría de los modelos."
          : faqSections[1]?.Card[10]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Por qué comprar un auto no es una inversión?"
          : faqSections[1]?.Card[11]?.Title,
      answer: (
        <div>
          {faqSections?.length === 0 || faqSections === null
            ? "La compra de un vehículo está clasificada como un gasto, ya que al vender el auto no recuperas ni ganas más de lo que te costó. De hecho, al sacarlo del concesionario, pierden entre un 10% a un 15% de su valor.Adicionalmente, debes contemplar otros gastos a la hora de comprar un 0 kilometro, como el impuesto verde, la inscripción, seguro, etc. Estos son gastos que te podrías ahorrar si contratas tu próximo 0 KM con tripp"
            : faqSections[1]?.Card[11]?.Paragraph}
          <a target="_blank" href="https://tripp.cl/" rel="noreferrer">
            {""} Click aqui.
          </a>
        </div>
      ),
    },
    {
      title: "¿Qué ocurre en caso de siniestro o robo total o parcial?",
      answer: (
        <div>
          Debes denunciar el siniestro a través{" "}
          <a href="https://gamaleasing.cl/siniestros">
            https://gamaleasing.cl/siniestros
          </a>{" "}
          o llamando al teléfono + 56 2 3278 5997 (Grúa y Asistencia) y al + 56
          2 2757 4450 (Emergencias).,
        </div>
      ),
    },
  ];
  const leasingOperativo = [
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Cuál es el plazo mínimo para contratar el servicio?"
          : faqSections[0]?.Card[0]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Debe ser por un plazo igual o superior a 12 meses."
          : faqSections[0]?.Card[0]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué tipo de vehículos tienen disponible?"
          : faqSections[0]?.Card[1]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Gama puede poner a disposición de tu negocio cualquier vehículo de trabajo que requieras, desde Camionetas Pick Up, Furgones pequeños y grandes, Furgones de pasajeros, y Camiones ¾ y grandes."
          : faqSections[0]?.Card[1]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Dónde se realizan las mantenciones de los vehículos?"
          : faqSections[0]?.Card[2]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "En los talleres propios de Gama."
          : faqSections[0]?.Card[2]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué ocurre en caso de siniestros o robo total o parcial?"
          : faqSections[0]?.Card[3]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Se debe denunciar el siniestro únicamente a través de la gamaleasing.cl/siniestro o llamando al teléfono + 56 2 3278 5997 (Grúa y Asistencia) y al + 56 2 2757 4450 (Emergencias)."
          : faqSections[0]?.Card[3]?.Paragraph,
    },
  ];

  const rentACar = [
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Quiénes pueden arrendar un auto?"
          : faqSections[3]?.Card[0]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null ? (
          <div>
            <p>Debes cumplir con los siguientes requisitos:</p>
            <ul>
              <li>- Edad mínima: 21</li>
              <li>
                - Requisito de licencia: Todas las clases de vehículos |
                categorías.
              </li>
            </ul>
          </div>
        ) : (
          faqSections[3]?.Card[0]?.Paragraph
        ),
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué medios de pagos aceptan para pagar el arriendo?"
          : faqSections[3]?.Card[1]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "A través de Tarjetas de crédito, débito y Mercado Pago."
          : faqSections[3]?.Card[1]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Dónde se realizan las mantenciones de los vehículos?"
          : faqSections[3]?.Card[2]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "En los talleres propios de Gama."
          : faqSections[3]?.Card[2]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Cuáles son las condiciones para la devolución de un pago por concepto de reservas?"
          : faqSections[3]?.Card[3]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "La reserva puede cancelarse antes del comienzo del arriendo por email. En caso de cancelación, se devolverá la cantidad pagada por anticipado, deduciendo las tasas de cancelación que asciende a la cantidad del precio de arriendo (como máximo 3 días, incluidos extras y tarifas). Las cancelaciones deben ser avisadas a Gama Rent, al email: Reservas@gamarent.cl."
          : faqSections[3]?.Card[3]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿De cuánto es la garantía que se cancela?"
          : faqSections[3]?.Card[4]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Dependerá del vehículo seleccionado y la cantidad de días."
          : faqSections[3]?.Card[4]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Puedo pagar la garantía con cheque o efectivo?"
          : faqSections[3]?.Card[5]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "No, la garantía debe ser pagada únicamente con tarjeta de crédito. Y el plazo de devolución dependerá de lo establecido por la entidad emisora."
          : faqSections[3]?.Card[5]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué licencia de conducir necesito?"
          : faqSections[3]?.Card[6]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Puedes conducir en Chile con una licencia de conducir vigente, en caso de ser extranjero, sirve la de tu país de origen."
          : faqSections[3]?.Card[6]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Existen un recargo por combustible?"
          : faqSections[3]?.Card[7]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Los vehículos se entregan con combustible, y deberán ser devueltos con el mismo nivel que se registró al momento de la entrega. De lo contrario, se realizará un cargo por reabastecimiento de combustible. No se harán devoluciones de dinero en caso de que el vehículo sea entregado con mayor nivel de combustible."
          : faqSections[3]?.Card[7]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué tipos de cargos adicionales podrían ser efectuados?"
          : faqSections[3]?.Card[8]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "*Cargo adicional por devolver el vehículo en una sucursal distinta a la que fue entregado. //*TAG, peajes o similar. //*Perdida de documentos.// *Servicios o coberturas adicionales que el cliente solicite. //*Deducible del Seguro, en caso de accidentes, volcamientos o robo. //*Devoluciones fuera de horario.// Para más detalles debes revisar el contrato de arriendo y sus anexos."
          : faqSections[3]?.Card[8]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué debo hacer en caso de accidente o robo?"
          : faqSections[3]?.Card[9]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Debes dar aviso inmediatamente a Carabineros de Chile.// Luego avisar a Gama Rent (dentro de un plazo máximo de 24 horas) al correo siniestros@gamarent.cl, con la siguiente información: // -Fotocopia de constancia o parte policial. // -Licencia de conducir del conductor.// -Cédula de identidad por ambos lados.// -Relato detallado de los hechos del siniestro.// Puedes llamar también al teléfono de emergencias: +562 232785997."
          : faqSections[3]?.Card[9]?.Paragraph,
    },
    {
      title:
        faqSections?.length === 0 || faqSections === null
          ? "¿Qué cobertura tiene el seguro incluido?"
          : faqSections[3]?.Card[10]?.Title,
      answer:
        faqSections?.length === 0 || faqSections === null
          ? "Cobertura de daños (CDW) // Su arriendo incluye cobertura daños, es posible limitar la responsabilidad en caso de deterioro del vehículo a un determinado deducible.// Deducibles daños: 11,90 UF Deducible volcamiento: 23,8 UF // Protección contra robo (TP) //Su arriendo incluye protección contra robo, es posible limitar la responsabilidad en caso de robo a un determinado deducible.// Deducible Robo: 35,7 UF // Revisar términos y condiciones."
          : faqSections[3]?.Card[10]?.Paragraph,
    },
  ];

  const [collapseData, setCollapseData] = useState(leasingOperativo);

  const styles = {
    active: {
      // visibility: "visible",
      transition: "all 0.2s ease-in-out",
    },
    hidden: {
      // visibility: "hidden",
      transition: "all 0.2s ease-in-out",
      transform: biggerThan900 && "translateY(-2.3rem)",
    },
  };

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div>
      <Layout>
        <section
          className="home-banner-container"
          id="header"
          style={{
            height: `80vh`,
          }}
        >
          <Row
            className="header-contain"
            style={{
              backgroundImage: `url(${
                faqHeader?.length === 0 || faqHeader === null
                  ? ContactBanner
                  : faqHeader?.bannerImage?.[0]?.url
              })`,
            }}
          >
            <Col
              id="navbar"
              style={
                scrollDirection === "down" || !scrollDirection
                  ? styles.active
                  : styles.hidden
              }
            >
              <nav className="home-nav-container">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={"https://gamamobility.cl/"}
                      rel="noreferrer"
                    >
                      GAMA MOBILITY
                    </a>
                  </li>
                  <li>
                    <Link
                      style={{
                        color:
                          window.location.pathname === "" ? "#FF3200" : "white",
                      }}
                      to={"/"}
                    >
                      GAMA LEASING
                    </Link>
                  </li>

                  <li>
                    <a href={"https://www.gamarent.cl/"}>RENT A CAR</a>
                  </li>
                  <li>
                    <a href={"https://www.tripp.cl/"}>TRIPP</a>
                  </li>
                  <li>
                    <Link to={"/usados"}>GAMA USADOS</Link>
                  </li>
                </ul>
              </nav>
              <header>
                <div className="home-nav-items">
                  <div className="home-nav-logo">
                    <Link to={"/mobility"}>
                      <Image
                        preview={false}
                        src={Logo}
                        style={{ width: "130px" }}
                      />
                    </Link>
                  </div>
                  <div className="group-nav-items">
                    <div
                      className="nav-container-buttons"
                      style={{
                        transition: "all ease-out 1s;",
                        opacity: `${visible ? "0" : "1"}`,
                        pointerEvents: `${visible ? "none" : "auto"}`,
                      }}
                    >
                      <Link to="/contacto">
                        <Button
                          onClick={() => {}}
                          className="gama-outline talk-button"
                        >
                          Conversemos
                        </Button>
                      </Link>
                    </div>
                    <div className="container-menu">
                      {biggerThan900 ? (
                        <div className="container-navlinks">
                          {isHome ? (
                            <>
                              <a smooth href="/sucursales">
                                Sucursales
                              </a>
                              <a smooth href="#servicios">
                                Nuestros Servicios
                              </a>
                              <a smooth href="#nosotros">
                                Quienes Somos
                              </a>
                              <a smooth href="#beneficios">
                                Beneficios
                              </a>
                              <a smooth href="#publicaciones">
                                Publicaciones
                              </a>
                            </>
                          ) : (
                            <>
                              <Link to="/sucursales">Sucursales</Link>
                              <Link to="/mobility#servicios">
                                Nuestros Servicios
                              </Link>
                              <Link to="/mobility#nosotros">Quienes Somos</Link>
                              <Link to="/mobility#beneficios">Beneficios</Link>
                              <Link to="/mobility#publicaciones">
                                Publicaciones
                              </Link>
                            </>
                          )}
                        </div>
                      ) : (
                        <Burger
                          data={burguerData}
                          modal={visible}
                          boton={false}
                          onClose={() => {
                            setVisible(!visible);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </header>
            </Col>
          </Row>
          <div className="banner-title-container">
            <h1
              style={{
                textAlign: "0",
                paddingLeft: "0",
                color: "#FF3200",
              }}
            >
              {faqHeader?.length === 0 || faqHeader === null
                ? "Preguntas Frecuentes"
                : faqHeader?.title?.Title}
            </h1>
            <h3
              style={{
                textAlign: "",
                lineHeight: "42px",
                fontSize: "40px",
                fontWeight: "400",
                width: biggerThan900 ? "1400px" : "100%",
                // maxWidth: biggerThan900 ? "800px" : "",
                // marginRight: biggerThan900 ? "1120px" : "",
                paddingLeft: "0",
              }}
            >
              {faqHeader?.length === 0 || faqHeader === null
                ? "En Gama nos importan las dudas y consultas de nuestros clientes. Encuentra información y respuestas acerca de cada uno de nuestros servicios."
                : faqHeader?.title?.Detail}
            </h3>
          </div>
        </section>
      </Layout>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginRight: biggerThan900 ? "50px" : "",
          }}
        >
          <Button
            onClick={() => setCollapseData(leasingOperativo)}
            style={{
              marginBottom: "25px",
              borderRadius: "5px",
              width: "205px",
            }}
          >
            {faqSections?.length === 0 || faqSections === null
              ? "Leasing Operativo >"
              : faqSections[0]?.Title}
          </Button>
          <Button
            style={{
              marginBottom: "25px",
              width: "205px",
              borderRadius: "5px",
            }}
            onClick={() => setCollapseData(Renting)}
          >
            {faqSections?.length === 0 || faqSections === null
              ? "Tripp >"
              : faqSections[1]?.Title}
          </Button>
          <Button
            style={{
              marginBottom: "25px",
              width: "205px",
              borderRadius: "5px",
            }}
            onClick={() => setCollapseData(Usados)}
          >
            {faqSections?.length === 0 || faqSections === null
              ? "Usados >"
              : faqSections[2]?.Title}
          </Button>
          <Button
            style={{
              marginBottom: "25px",
              width: "205px",
              borderRadius: "5px",
            }}
            onClick={() => setCollapseData(rentACar)}
          >
            {faqSections?.length === 0 || faqSections === null
              ? "Rent a Car >"
              : faqSections[3]?.Title}
          </Button>
        </Row>
        <Row style={{ paddingLeft: "25px", paddingRight: "25px" }}>
          <Col>
            {collapseData.map((panel) => (
              <Collapse
                key={panel.title}
                style={{ minWidth: biggerThan900 ? "900px" : "" }}
                defaultActiveKey={["0"]}
                onChange={onChange}
              >
                <Panel header={panel.title} key={panel.title}>
                  <p style={{ width: biggerThan900 ? "850px" : "100%" }}>
                    {panel.answer}
                  </p>
                </Panel>
              </Collapse>
            ))}
          </Col>
        </Row>
      </Row>
      <Footer logo={Logo} />
    </div>
  );
}

export default FAQ;
